<template>
  <div class="bg-zinc-950 h-full flex-1 min-h-screen" :class="[!hasInit && 'overflow-hidden relative h-screen']">
    <Transition name="fade">
      <div v-if="!hasInit" class="absolute inset-0 w-full h-full z-100 bg-zinc-950 flex justify-center items-center">
        <div class="!w-30 !h-30">
          <img :src="spinner" class="w-full h-full" />
        </div>
      </div>
    </Transition>

    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-50 xl:hidden" @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-zinc-950/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-zinc-950 px-6 ring-1 ring-white/10">
                <div class="flex h-16 shrink-0 items-center">
                  <NuxtLink to="/" class="shrink-0 -m-1.5">
                    <span class="sr-only">MattePaint</span>
                    <img src="~/assets/img/logo_full.png" class="h-9 hidden lg:flex shrink-0" alt="MattePaint Logo" />
                    <img src="~/assets/img/icon_white.png" loading="lazy" class="h-9 flex lg:hidden shrink-0" alt="MattePaint Icon" />
                  </NuxtLink>
                </div>
                <span class="inline-flex items-center rounded-md  px-2 py-2 text-sm font-medium  ring-1 ring-inset  space-x-2 -mx-2" :class="[isBusiness ? 'bg-red-400/10 text-red-400 ring-red-400/30' : 'bg-blue-400/10 text-blue-400 ring-blue-400/30']">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                  </svg>

                  <span>{{ isBusiness ? 'Business' : 'Personal' }} Account</span>
                </span>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <NuxtLink :to="item.href" :class="[item.current ? 'bg-gray-900/50 text-white' : 'text-gray-200 hover:text-white hover:bg-gray-900/50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                            <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                            {{ item.name }}
                          </NuxtLink>
                        </li>
                      </ul>
                    </li>
                    <!-- <li>
                      <div class="text-xs font-semibold leading-6 text-white">
                        Double Negative
                      </div>
                      <ul role="list" class="-mx-2 mt-2 space-y-1">
                        <li v-for="team in teams" :key="team.name">
                          <a :href="team.href" :class="[team.current ? 'bg-gray-900/50 text-white' : 'text-gray-200 hover:text-white hover:bg-gray-900/50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                            <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-900/50 text-[0.625rem] font-medium text-gray-200 group-hover:text-white">{{ team.initial }}</span>
                            <span class="truncate">{{ team.name }}</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li class="-mx-6 mt-auto">
                      <a href="#" class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-900/50">
                        <img class="h-8 w-8 rounded-full bg-gray-900/50" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                        <span class="sr-only">Your profile</span>
                        <span aria-hidden="true">Tom Cook</span>
                      </a>
                    </li> -->
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-black/10 px-6 ring-1 ring-white/5">
        <div class="flex h-16 shrink-0 items-center">
          <NuxtLink to="/" class="shrink-0 -m-1.5">
            <span class="sr-only">MattePaint</span>
            <img src="~/assets/img/logo_full.png" class="h-9 hidden lg:flex shrink-0" alt="MattePaint" />
            <img src="~/assets/img/icon_white.png" loading="lazy" class="h-9 flex lg:hidden shrink-0" alt="MattePaint" />
          </NuxtLink>
        </div>
        <span class="inline-flex items-center rounded-md  px-2 py-2 text-sm font-medium  ring-1 ring-inset  space-x-2 -mx-2" :class="[isBusiness ? 'bg-red-400/10 text-red-400 ring-red-400/30' : 'bg-blue-400/10 text-blue-400 ring-blue-400/30']">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
          </svg>

          <span>{{ isBusiness ? 'Business' : 'Personal' }} Account</span>
        </span>
        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <NuxtLink :to="item.href" :class="[item.current ? 'bg-gray-900/50 text-white' : 'text-gray-200 hover:text-white hover:bg-gray-900/50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                    <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                    {{ item.name }}
                  </NuxtLink>
                  <ul v-if="item.children && item.current" role="list" class="space-y-1">
                    <li v-for="c in item.children" :key="c.name">
                      <NuxtLink :to="c.href" :class="[ 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-200 hover:text-white  pl-11']">
                        {{ c.name }}
                      </NuxtLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <!-- <li>
              <div class="text-xs font-semibold leading-6 text-white">
                Double Negative
              </div>
              <ul role="list" class="-mx-2 mt-2 space-y-1">
                <li v-for="team in teams" :key="team.name">
                  <a :href="team.href" :class="[team.current ? 'bg-gray-900/50 text-white' : 'text-gray-200 hover:text-white hover:bg-gray-900/50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                    <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-900/50 text-[0.625rem] font-medium text-gray-200 group-hover:text-white">{{ team.initial }}</span>
                    <span class="truncate">{{ team.name }}</span>
                  </a>
                </li>
              </ul>
            </li>
            <li class="-mx-6 mt-auto">
              <a href="#" class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-900/50">
                <img class="h-8 w-8 rounded-full bg-gray-900/50" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                <span class="sr-only">Your profile</span>
                <span aria-hidden="true">Tom Cook</span>
              </a>
            </li> -->
          </ul>
        </nav>
      </div>
    </div>

    <div class="xl:pl-72">
      <!-- Sticky search header -->
      <div class="sticky top-0 z-40 flex h-16 shrink-0 items-center justify-between gap-x-6 border-b border-white/5 bg-zinc-950 px-4 shadow-sm sm:px-6 lg:px-8">
        <button type="button" class="-m-2.5 p-2.5 text-white xl:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-5 w-5" aria-hidden="true" />
        </button>

        <div class="hidden sm:-my-px sm:flex sm:space-x-8 h-16">
          <a v-for="item in topNavigation" :key="item.name" :href="item.href" no-prefetch :class="[item.current ? 'border-orange-500 text-white' : 'border-transparent text-gray-300 hover:border-gray-300 hover:text-gray-100', 'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
        </div>

        <HeaderAccountMenu />
      </div>

      <NuxtPage class="max-w-[2560px] m-auto z-10 relative" />
    </div>

    <ClientOnly>
      <GModal v-model="showResetPassword" sticky :show-close="false">
        <ModalResetPassword @close="showResetPassword=false" />
      </GModal>
    </ClientOnly>
  </div>
</template>

<script>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
  AcademicCapIcon,
  Cog6ToothIcon,
  FolderIcon,
  GlobeAltIcon,
  ServerIcon,
  SignalIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import { Bars3Icon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
import spinner from '~/assets/img/spinner2.svg?inline';
// eslint-disable-next-line promise/param-names
const delay = ms => new Promise(r => setTimeout(r, ms));

export default {
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    AcademicCapIcon,
    Cog6ToothIcon,
    FolderIcon,
    GlobeAltIcon,
    ServerIcon,
    SignalIcon,
    XMarkIcon,
    Bars3Icon,
    MagnifyingGlassIcon
  },
  emits: ['login', 'error'],
  setup() {
    const CFIP = useCookie('CF-IPCountry')
    return { CFIP }
  },
  data() {
    return {
      spinner,
      showResetPassword: false,
      loading: false,
      reason: null,
      beacon: false,
      handledIdle: false,
      sidebarOpen: false,
      topNavigation: [
        { name: 'My Account', href: '/account/', current: true },
        { name: 'Photo Gallery', href: '/gallery/', current: false },
        { name: 'HDRI\'s', href: '/gallery/hdri/skies/', current: false },
        { name: 'Image Packs', href: '/gallery/packs/', current: false },
        { name: 'Academy', href: '/academy/', current: false },
      ],
      teams: [
        { id: 1, name: 'MattePainters', href: '#', initial: 'MP', current: false },
        { id: 2, name: 'Lighters', href: '#', initial: 'LI', current: false },
        { id: 3, name: 'Dune 2', href: '#', initial: 'D2', current: false },
      ]
    }
  },
  computed: {
    navigation() {
      const { $route } = this;
      const { path } = $route || {};
      return [
        {
          name: 'Account Settings',
          href: '/account/',
          icon: FolderIcon,
          current: `${path}` === '/account/',
          // children: [
          //   { name: 'Your details', href: '#' },
          //   { name: 'Change password', href: '#' },
          //   { name: 'Change email address', href: '#' },
          // ], 
        },
        {
          name: 'Subscription & Credits',
          href: '/account/subscription/',
          icon: ServerIcon,
          current: `${path}`.includes('/account/subscription/'),
          // children: [
          //   { name: 'Your subscription', href: '#' },
          //   { name: 'Premium credits', href: '#' },
          //   { name: 'Cost control', href: '#' },
          //   { name: 'Credit spend', href: '#' },
          //   { name: 'Billing details', href: '#' },
          //   { name: 'Invoices', href: '#' },
          // ], 
        },
        { name: 'My Downloads', href: '/account/downloads/', icon: SignalIcon, current: `${path}`.includes('/account/downloads/'), },
        { name: 'My Collections', href: '/account/collections/', icon: GlobeAltIcon, current: `${path}`.includes('/account/collections/'), },
        // { name: 'MattePaint Academy', href: '/account/academy/', icon: AcademicCapIcon, current: `${path}`.includes('/account/academy/'), },
        // { name: 'Referrals', href: '/account/', icon: FolderIcon, current: `${path}`.includes('/account/subscription/'), },
        // { name: 'Manage Business', href: '#', icon: Cog6ToothIcon, current: `${path}`.includes('/account/subscription/'), },
      ]
    }
  },
  watch: {
    isSessionVerified(v) {
      if (v) {
        this.$store.user.GetUser();
      } else if (this.isAuthed) {
        // show 2fa modal?
      }
    },
    user(v) {
      if (v && v.resetPassword) {
        this.showResetPassword = true;
      }
    }
  },
  async beforeMount() {
    if (this.CFIP) {
      this.$store.base.SetCountry(this.CFIP);
    }
    await this.initApp();
  },
  mounted() {
    this.$mitt.on('network-idle', () => {
      this.networkIsIdle();
    });

    this.$mitt.on('open-beacon', () => {
      if (!this.beacon) {
        this.mountBeacon();
      }
      this.openBeacon();
    });

    this.$mitt.on('open-support', () => {
      if (!this.beacon) {
        this.mountBeacon();
      }
      this.openSupport();
    });

    this.detectIdle();

    if (!this.isAuthed) {
      navigateTo('/login/?redir=/account/');
    }

    setTimeout(() => {
      this.networkIsIdle();
    }, 5000);
  },
  methods: {
    emitNetworkIdle() {
      this.$mitt.emit('network-idle');
      this.networkIsIdle();
      this.$store.base._idle = true;
    },
    networkIsIdle() {
      if (this.handledIdle) {
        return;
      }
      
      this.handledIdle = true;
      this.beconInit();

      setTimeout(() => {
        this.mountBeacon();
      }, 100);
    },
    detectIdle() {
      let timer;

      const observer = new PerformanceObserver((items) => {
        items
          .getEntries()
          .filter(({ initiatorType }) => initiatorType === 'fetch')
          .forEach(() => {
            if (timer) {
              clearTimeout(timer);
            }
            timer = setTimeout(() => this.emitNetworkIdle(), 2000);
          });
      });

      observer.observe({
        entryTypes: ['resource'],
      });
    },
    async mountBeacon() {
      if (this.beacon) {
        return Promise.resolve();
      }
      if (!window.Beacon) {
        this.beconInit();
        await delay(500);
        return this.mountBeacon();
      }
      window.Beacon('init', 'a8072d9b-b804-44ab-abf8-36392971f10c');
      this.beacon = true;
    },
    async openBeacon() {
      if (!window.Beacon) {
        await delay(100);
        return this.openBeacon();
      }
      window.Beacon('open');
    },
    async openSupport() {
      if (!window.Beacon) {
        await delay(100);
        return this.openSupport();
      }
      window.Beacon('open');
      setTimeout(() => {
        window.Beacon('navigate', '/ask/chat/');
      }, 0);
    },
    beconInit() {
      if (window.Beacon) {
        return;
      }
      // eslint-disable-next-line no-unused-expressions, no-sequences
      !(function(e, t, n) { function a() { let e = t.getElementsByTagName('script')[0]; let n = t.createElement('script'); n.type = 'text/javascript', n.async = !0, n.src = 'https://beacon-v2.helpscout.net', e.parentNode.insertBefore(n, e) } if (e.Beacon = n = function(t, n, a) { e.Beacon.readyQueue.push({ method: t, options: n, data: a }) }, n.readyQueue = [], t.readyState === 'complete') { return a(); } e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1) }(window, document, window.Beacon || function() {}));
    },
  }
}
</script>